<template>

  <!-- full dealer bar container -->
  <div
    id="dealer-bar-container"
    :style="dealerBarStyle"
  >
    <div id="dealer-bar">

      <!-- double logo container -->
      <div id="brand-dealer-logo-container">
        <img style="margin-left: 20px; " class="hide-sm" :src="brandLogoUrl">
        <!-- <img style="margin-left: 25px; padding: 9px;" src="@/assets/dealer_logo.png"> -->
        <!-- <p id="dealer-name">{{ dealer.dealerName }}</p> -->
        <p id="dealer-name">Walser Chrysler Jeep Dodge RAM</p>        
      </div>

      <!-- desktop double info container -->
      <div class="d-flex">
        <!-- phone container -->
        <a @click.prevent="openContactUrl" href="#" id="phone-container" class="contact-link">
          <img style="margin: 0px 8px 0px 10px; height: 25px; opacity: 0.8;" src="@/assets/icon_phone_white.svg">
          <span style="display: flex; flex-direction: column; font-size: 13px; line-height: 16px; font-weight: 600;" class="hide-sm hide-md hide-lg">
            CONTACT
          </span>
        </a>
        <!-- address container -->
        <a @click.prevent="openContactUrl" href="#" id="location-container" class="contact-link">
          <img style="display: flex; margin: 0px 10px 0px 10px; opacity: 0.8; width: 18px;" src="@/assets/icon_map_marker_white.svg">
          <span style="display: flex; flex-direction: column; font-size: 13px; line-height: 16px; font-weight: 600;" class="hide-sm hide-md hide-lg">
            MAP
          </span>
        </a>
      </div>

    </div>

  </div>
  <!-- end full dealer bar container -->


</template>

<script>

export default {

  name: "the-dealer-bar",

  props: {
      dealer: {
        type: Object
      },
      activeMake: {
        type: String
      },
    },

    methods: {
      openContactUrl() {
        alert('Ready to link off when the data is available!');
        // window.location.href = this.dealer.contactUrl;
      }
    },

    computed: {
      
      dealerBarStyle() {
        return {
          'background-color': this.$store.getters.activeTheme.make,
          'color': this.$store.getters.activeTheme.fontColor,
        }
      },

      brandLogoUrl() {
        let url = '';
        switch(this.$store.getters.activeTheme.make){
          case  'chrysler':
            url = './brand_logo_C_light.png';
          break;
          case  'jeep':
            url = './brand_logo_J_light.png';
          break;
          case  'wagoneer':
            url = './brand_logo_W_light.png';
          break;
          case 'dodge':
            url = './brand_logo_D_light.png';
          break;
          case 'ram':
            url = './brand_logo_R_light.png';
          break;
          case 'fiat':
            url = './brand_logo_F_light.png';
          break;
          case 'alfa_romeo':
            url = './brand_logo_A_light.png';
          break;
        }
        return url;
      }
    }
  };

</script>

<style scoped>

  #phone-container #location-container img{
    align-items: flex-start;
  }

  .contact-link :hover {
    opacity: 0.8;
  }

  #dealer-bar a {
    text-decoration: none;
    color: white;
  }

  @media only screen and (max-width: 768px) {

    #brand-dealer-logo-container {
      display: flex;
      width: 50%;
      font-size: 13px;
    }

    #dealer-name {
      margin-left: 30px;
      font-weight: 600;
      display: flex;
      align-self: center;
    }

    #phone-container {
      display: flex;
      align-items: center;
      padding: 0px 10px 0px 10px;
    }

    #location-container {
      display: flex;
      align-items: center;
      padding: 0px 20px 0px 10px;
    }

  }

  @media only screen and (max-width: 992px) and (min-width: 769px) {
    #brand-dealer-logo-container {
      display: flex;
      width: 550px;
    }

    #dealer-name {
      margin-left: 30px;
      font-weight: 600;
      display: flex;
      align-self: center;
    }

    #phone-container {
      display: flex;
      align-items: center;
      padding: 0px 20px 0px 20px;
    }

    #location-container {
      display: flex;
      align-items: center;
      padding: 0px 40px 0px 20px;
    }

  }
  
  @media only screen and (max-width: 1400px) and (min-width: 993px){
    #brand-dealer-logo-container {
      display: flex;
      width: 650px;
    }

    #dealer-name {
      margin-left: 30px;
      font-weight: 600;
      display: flex;
      align-self: center;
    }

    #phone-container {
      display: flex;
      align-items: center;
      padding: 0px 20px 0px 20px;
    }

    #location-container {
      display: flex;
      align-items: center;
      padding: 0px 40px 0px 20px;
    }
  }

  @media only screen and (min-width: 1400px) {
    #brand-dealer-logo-container {
      display: flex;
      width: 650px;
    }

    #dealer-name {
      margin-left: 30px;
      font-weight: 600;
      display: flex;
      align-self: center;
    }

    #phone-container {
      display: flex;
      align-items: center;
      padding: 0px 20px 0px 20px;
    }

    #location-container {
      display: flex;
      align-items: center;
      padding: 0px 40px 0px 20px;
    }
  }

  #dealer-bar-container {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  #dealer-bar {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    max-width: 1440px;
  }

</style>
