<template>

  <div style="background-color: white; padding: 10px;">
    <h2
      :class="$store.getters.activeTheme.fontBold"
      style="margin: 30px 0px 40px 0px; text-align: center;"
    >
      EXPLORE MODELS
    </h2>

    <div class="jellybean-nav-container">
      <a
        v-for="(make, index) in makes" :key="index"
        @click.prevent="setMake(make)"
        href="#"
      >
        <img
          :class="selectedMake == make ? 'active-border' : ''"
          :src="getBrandLogo(make)"
          style="height: 100%; border-bottom-color: #063f74;"
          :style="{'border-bottom-color': getAccentColor(make)}"
          draggable="false"
        >
      </a>
    
    </div>

    <div class="lineup-container" style="padding-bottom: 30px;">
      <router-link
        v-for="(model,index) in modelsSansWagoneer" :key="index"
        :to="{ path: '/videos', query: { d: dealerCode, m: model.modelCode }}"
        href="#"
        :class="selectedMake != model.make.toLowerCase() ? 'd-none' : ''"
        class="jellybean-container"
        style="text-decoration: none; color: black;"
      >
        <img style="max-width: 90%;" :src="model.jellybeanUrl">
        <p style="margin-top: 3px; font-weight: 600; text-transform: uppercase;">{{ model.model }}</p>
      </router-link>
    </div>


    <!-- Only for wagoneer models - can't be mixed with Jeep -->
    <div v-if="selectedMake == 'jeep'">
      <div class="wagoneer-logo-container">
        <img style="max-width: 100%;" src="@/assets/wagoneer_logo.png">
      </div>

      <div class="lineup-container">

        <router-link
          v-for="(model,index) in modelsWagoneer" :key="index"
          :to="{ path: '/videos', query: { d: dealerCode, m: model.modelCode }}"
          href="#"
          :class="selectedMake != model.make.toLowerCase() ? 'd-none' : ''"
          class="jellybean-container"
          style="text-decoration: none; color: black;"
        >
          <img style="max-width: 90%;" :src="model.jellybeanUrl">
          <p style="margin-top: 3px; font-weight: 600; text-transform: uppercase;">{{ model.model }}</p>
        </router-link>
      </div>
    </div>

  </div>

  

</template>

<script>

export default {

  name: "the-recommended-models",

  props: {
    modelStubData: {
      type: Object,
      default: () => ({}),
    },
    activeMake: {
      type: String
    },
  },

  data() {
    return {
      selectedMake: this.activeMake,
      dealerCode: this.$route.query.d,
      modelCode: this.$route.query.m,
      makes: [
        "chrysler",
        "dodge",
        "jeep",
        "ram",
        "fiat",
      ],
    };
  },

  methods: {

    setMake(make) {
      this.selectedMake = make;
    },

    getImage(fileName) {
      return '/jellybeans/' + this.selectedMake + '/' + fileName;
    },

    getBrandLogo(make) {
      return "/brand_logo_" + make.charAt(0).toUpperCase() + ".png";
    },

    getAccentColor(make) {
      return this.$store.state.themes.find(theme => theme.make == make).accentColor;
    },

  },

  computed: {
    
    modelsSansWagoneer() {
      return this.modelStubData.filter(model => model.brandInitial != 'W');
    },

    modelsWagoneer() {
      return this.modelStubData.filter(model => model.brandInitial == 'W');
    }

  }

};

</script>

<style scoped>

.lineup-container img {
  -webkit-animation: fadein 300ms; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 300ms; /* Firefox < 16 */
  -ms-animation: fadein 300ms; /* Internet Explorer */
  -o-animation: fadein 300ms; /* Opera < 12.1 */
  animation: fadein 300ms;
}

.active-border {
  border-bottom-width: 4px;
  border-bottom-style: solid;
}

.jellybean-container {
  text-align: center;
  margin-bottom: 15px;
  width: 100%;
}

.lineup-container {
  border-top: 1px solid rgb(207, 207, 207);
  padding-top: 45px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0px auto 0px auto;
  max-width: 1200px;
}

.lineup-container :hover {
  opacity: 0.9;
  color: #063f74;
}

.wagoneer-logo-container {
  border-top: 1px solid rgb(207, 207, 207);
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0px auto 0px auto;
  max-width: 1200px;
  background-color: #16255e;
  height: 80px;
}

@media only screen and (min-width: 993px) {
  .jellybean-container {
    max-width: 25%;
  }

  .jellybean-nav-container {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    height: 78px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(246, 246, 246);
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  .jellybean-nav-container a {
    border-bottom-width: 0px;
    border-bottom-style: solid;
    height: 70px;
  }

  .jellybean-nav-container a :hover {
    border-bottom-width: 4px;
    border-bottom-style: solid;
    transition: 50ms ease all;
  }

  .jellybean-nav-container img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
}

@media only screen and (max-width: 992px) and (min-width: 769px) {

  .lineup-container {
    padding: 40px 0px 0px 0px;
  }
  .jellybean-container {
    max-width: 33%;
  }

  .jellybean-nav-container {
    text-align: center;
    height: 64px;
    background-color: rgb(246, 246, 246);
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .jellybean-nav-container a {
    border-bottom-width: 0px;
    border-bottom-style: solid;
    height: 60px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 577px) {
  .jellybean-container {
    max-width: 50%;
  }

  .jellybean-nav-container {
    text-align: center;
    height: 54px;
    background-color: rgb(246, 246, 246);
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .jellybean-nav-container a {
    border-bottom-width: 0px;
    border-bottom-style: solid;
    height: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .jellybean-container {
    max-width: 50%;
  }

  .jellybean-nav-container {
    text-align: center;
    height: auto;
    background-color: rgb(246, 246, 246);
    padding-bottom: 0px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    padding: 10px 0px 10px 0px;
  }

  .jellybean-nav-container a {
    border-bottom-width: 0px;
    border-bottom-style: solid;
    height: 50px;
    margin: 10px;
  }

}

@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-moz-keyframes fadein { /* Firefox */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@-o-keyframes fadein { /* Opera */
    from {
        opacity:0;
    }
    to {
        opacity: 1;
    }
}

</style>
